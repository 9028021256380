@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'Gotham Narrow';
  src: url('https://www.guilded.gg/fonts/gotham-narrow-book.woff2') format('woff2'),
    url('https://www.guilded.gg/fonts/gotham-narrow-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Narrow';
  src: url('https://www.guilded.gg/fonts/gotham-narrow-book-italic.woff2') format('woff2'),
    url('https://www.guilded.gg/fonts/gotham-narrow-book-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Narrow';
  src: url('https://www.guilded.gg/fonts/gotham-narrow-bold.woff2') format('woff2'),
    url('https://www.guilded.gg/fonts/gotham-narrow-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Narrow';
  src: url('https://www.guilded.gg/fonts/gotham-narrow-bold-italic.woff2') format('woff2'),
    url('https://www.guilded.gg/fonts/gotham-narrow-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
